<template>
  <div>
    <b-modal
        hide-footer
        id="modal-prevent-closing-extra-medical"
        ref="my-modal"
        :title="`Crear medico extra`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Nombre doctor"
          >
            <b-form-input
                v-model="medicalExtra.name"
                placeholder="Nombre doctor"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Especialidd"
          >
            <b-form-input
                v-model="medicalExtra.specialty"
                placeholder="Especialidd"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Teléfono"
          >
            <b-form-input
                v-model="medicalExtra.phone"
                placeholder="Teléfono"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Cedula del Médico"
          >
            <b-form-input
                v-model="medicalExtra.identification_medical"
                placeholder="Cedula del Médico"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Dirección"
          >
            <b-form-input
                v-model="medicalExtra.address"
                placeholder="Dirección"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="createMedical"
              style="width: 200px"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Guardar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row>
      <b-col
          cols="12" lg="3"
      >
        <b-button
            @click="newConsultations"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Crear Inter Consulta
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in consults" :key="idx"
                               @click="viewConsultation(a.id)"> {{ resHoursOdooFormat(a.createdAt) }}(ver
              Inter consulta) <br> <span v-if="a.HrEmployee">{{ a.HrEmployee.name }}</span>
            </b-list-group-item>
          </b-list-group>
        </div>

      </b-col>
      <b-col cols="12" lg="9" v-if="createConsultations">
        <h3 style="text-align: center;">Inter Consulta</h3>

        <b-row>
          <b-col cols="12" style="margin-bottom: 20px">
            <div align="right">
              <b-button
                  @click="exportInterPrint()"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Imprimir
              </b-button>
              <b-button
                  @click="showModalMedicals"
                  style="width: 200px; margin-left: 30px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Asignar médico extra
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <b-form-group
                label="Medico Relacionado"
                label-for="h-email"
            >
              <v-select
                  @input="change_extra_medical(false)"
                  v-model="extra_select"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="medicals"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="12">
            <b-form-group
                label="Especialidad del Médico"
            >
              <b-form-input
                  v-model="consul.specialty"
                  placeholder="(Especialidad del médico externo)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="12">
            <b-form-group
                label="Teléfono del Médico"
            >
              <b-form-input
                  v-model="consul.phone"
                  placeholder="(Teléfono del médico externo)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="12">
            <b-form-group
                label="Cedula del Médico"
            >
              <b-form-input
                  v-model="consul.identification_medical"
                  placeholder="(Cedula del médico externo)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="12">
            <b-form-group
                label="Dirección del Médico"
            >
              <b-form-input
                  v-model="consul.address"
                  placeholder="(Dirección del médico externo)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="12">
            <b-form-group
                label="Motivo de inter consulta"
            >
              <b-form-textarea
                  rows="2"
                  max-rows="999999"
                  v-model="consul.reason"
                  placeholder="Motivo de inter consulta"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="12">
            <b-form-group
                label="Diagnostico ultima consulta"
            >
              <b-form-textarea
                  rows="2"
                  max-rows="999999"
                  v-model="consul.last_consult"
                  placeholder="Diagnostico ultima consulta"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-button
                @click="createConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" lg="9" v-if="updateInformation">


        <b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <h3 style="text-align: left;"> Inter Consulta</h3>
            </b-col>
            <b-col cols="12" lg="3">

              <a style="text-align: right" @click="deleteLineInter(consultations.id)">
                <feather-icon icon="TrashIcon" style="color: darkred" size="50"/>
              </a>


            </b-col>
            <b-col cols="12" lg="3">
              <b-button
                  @click="exportInterPrintUpdate"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Imprimir
              </b-button>
              <b-button
                  @click="showModalMedicals"
                  style="width: 200px"
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
              >
                Asignar médico extra
              </b-button>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Medico Relacionado"
                  label-for="h-email"
              >
                <v-select
                    @input="change_extra_medical(true)"
                    v-model="extra_select"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="medicals"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Especialidad del Médico"
              >
                <b-form-input
                    v-model="consultations.specialty"
                    placeholder="(Especialidad del médico externo)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Teléfono del Médico"
              >
                <b-form-input
                    v-model="consultations.phone"
                    placeholder="(Teléfono del médico externo)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Cedula del Médico"
              >
                <b-form-input
                    v-model="consultations.identification_medical"
                    placeholder="(Cedula del médico externo)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Dirección del Médico"
              >
                <b-form-input
                    v-model="consultations.address"
                    placeholder="(Dirección del médico externo)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Motivo de inter consulta"
              >
                <b-form-textarea
                    rows="2"
                    max-rows="999999"
                    v-model="consultations.reason"
                    placeholder="Motivo de inter consulta"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="12">
              <b-form-group
                  label="Diagnostico ultima consulta"
              >
                <b-form-textarea
                    rows="2"
                    max-rows="999999"
                    v-model="consultations.last_consult"
                    placeholder="Diagnostico ultima consulta"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-button
                  @click="updateConsult()"
                  style="width: 100%"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>


        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio, BFormSelect,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem, BModal, BOverlay,
  BRow, VBModal, BFormCheckbox
} from "bootstrap-vue";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from 'moment';
import logo from "../../assets/images/logo/logo-1.png"
import signature from "../../assets/images/logo/signature.png"
import vSelect from "vue-select";
import {dateTodayFormat, formatHour, sumMonthsHours} from "@/utils/date";

export default {
  name: "InterConsultationsPatient",
  components: {
    vSelect,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    title: String,
    data_patient: Object
  },
  data() {
    return {
      medicals: [],
      medicalsAll: [],
      extra_select: {id: "", value: "Seleccione un medico"},
      consultations: {
        id: null,
        name: null,
        specialty: null,
        phone: null,
        identification_medical: null,
        address: null,
        patient_id: null,
        appointee_id: null,
        medical_inter_consultations_id: null,
        reason: null,
        study_school: null,
        visa_no: null,
        permit_no: null,
        vat: null,
        name_doctor: null,
        folio: null,
        study_field: null,
        last_consult: null,
        date_consultation_hours: null,
        img_qr: null,
        years: null
      },
      consults: [],
      patient_id: null,
      diagnosisData: null,
      show: false,
      createConsultations: false,
      updateInformation: false,
      medicalExtra: {
        name: null,
        specialty: null,
        phone: null,
        identification_medical: null,
        address: null,
      },
      consul: {
        name: null,
        specialty: null,
        medical_inter_consultations_id: null,
        reason: null,
        phone: null,
        last_consult: null,
        identification_medical: null,
        address: null,
        patient_id: null,
        appointee_id: null,
        study_school: null,
        visa_no: null,
        permit_no: null,
        vat: null,
        name_doctor: null,
        folio: null,
        study_field: null,
        date_consultation_hours: dateTodayFormat(),
        img_qr: null,
        years: this.data_patient.birthday
            ? this.yearsCalculate(this.data_patient.birthday)
            : null,
      }
    }
  },

  mounted() {
    this.$root.$on('chargeInterConsultation', async () => {
      // your code goes here
      this.consul.study_school = this.getUser.study_school;
      this.consul.visa_no = this.getUser.visa_no;
      this.consul.permit_no = this.getUser.permit_no;
      this.consul.vat = this.getUser.ResPartnerAuth ? this.getUser.ResPartnerAuth?.vat : '';
      this.consul.name_doctor = this.getUser.name;
      this.consul.folio = this.getUser.folio;
      this.consul.study_field = this.getUser.study_field;
      this.consul.img_qr = this.getUser.img_qr;
      this.patient_id = this.data_patient.id
      await this.getConsultations();
      await this.getDiagnosis();
    })
  },
  methods: {
    ...mapActions('inter_consultations', ['findPatientAllInterConsultationsDesc',
      'createInterConsultation',
      'updateInterConsultation',
      'findPatientOneInterConsultationsDesc',
      'deleteInterConsultations',
      'getMedicalsInter',
      'createExtraMedical',
    ]),
    ...mapActions("patient", [
      "generateQrStudies","findPatientOne",'getFindFullName'
    ]),
    ...mapActions("patient", [
      "findOneDescConsultation"
    ]),
    async getDiagnosis(){
      const response = await this.findOneDescConsultation(this.patient_id);
      let resultName = []
      if (response.consultation){
        if (response.consultation.ClinicDiagnostics.length >= 0){
          for (const a of response.consultation.ClinicDiagnostics){
            resultName.push(a.name)
          }
          if (resultName.length >= 0){
           this.consul.last_consult = resultName.map(item => `${item}`).join(',');
          }
        }
      }
    },
    async getDiagnosisUpdate(){
      const response = await this.findOneDescConsultation(this.patient_id);
      let resultName = []
      if (response.consultation){
        if (response.consultation.ClinicDiagnostics.length >= 0){
          for (const a of response.consultation.ClinicDiagnostics){
            resultName.push(a.name)
          }
          if (resultName.length >= 0){
            if (!this.consultations.last_consult){
              this.consultations.last_consult = resultName.map(item => `${item}`).join(',');
            }

          }
        }
      }
    },
    change_extra_medical(edit) {
      if (!edit) {
        this.consul.medical_inter_consultations_id = this.extra_select.id;
        const findMedical = this.medicalsAll.find((a) => a.id === this.extra_select.id);
        if (!findMedical) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este medico',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }

        this.consul.name = findMedical.name;
        this.consul.identification_medical = findMedical.identification_medical;
        this.consul.address = findMedical.address;
        this.consul.specialty = findMedical.specialty;
        this.consul.phone = findMedical.phone;
      } else {
        this.consultations.medical_inter_consultations_id = this.extra_select.id;
        const findMedical = this.medicalsAll.find((a) => a.id === this.extra_select.id);
        if (!findMedical) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este medico',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.consultations.name = findMedical.name;
        this.consultations.identification_medical = findMedical.identification_medical;
        this.consultations.address = findMedical.address;
        this.consultations.specialty = findMedical.specialty;
        this.consultations.phone = findMedical.phone;
      }

    },

    async getAllDoctors() {
      this.show = true;
      const response = await this.getMedicalsInter();

      this.medicals = [];
      this.medicalsAll = [];
      for (const a of response) {
        this.medicals.push({
          id: a.id,
          value: a.name,
        })
      }
      this.medicalsAll = response;
      this.show = false;

    },
    async deleteLineInter(id) {
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres borrar la inter consulta?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.show = true
          const response = await this.deleteInterConsultations(id);
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Borro!',
              text: 'Su archivo se borro correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.getConsultations()
            this.createConsultations = false
            this.updateInformation = false
            this.show = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al borrar la inter consulta',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })
    },
    async getConsultations() {
      const responseDesc = await this.findPatientAllInterConsultationsDesc(this.data_patient.id);
      this.consults = responseDesc
    },
    async verifyDataEdit() {
      if (!this.consultations.medical_inter_consultations_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo medico requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }

      if (!this.consultations.specialty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Especialidad es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.consultations.phone) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo telefono es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.consultations.identification_medical) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo cedula   es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.consultations.address) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo direccion es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      return true
    },
    async verifyData() {
      if (!this.consul.medical_inter_consultations_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo medico requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }

      if (!this.consul.specialty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Especialidad es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.consul.phone) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo telefono es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.consul.identification_medical) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo cedula   es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      if (!this.consul.address) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo direccion es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      return true
    },
    async updateConsult() {
      const responseVerify = await this.verifyDataEdit();
      if (!responseVerify) return;
      this.show = true
      const date =  this.consultations.date_consultation_hours
      delete this.consultations.date_consultation_hours
      const response = await this.updateInterConsultation(this.consultations);

      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se edito correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getConsultations();
        await this.viewConsultation(this.consultations.id);
        this.createConsultations = false
        this.show = false
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
      }
    },
    async deleteFileConsultant(id) {
      this.show = true
      const response = await this.deleteFileRequest(id)
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se Borro el archivo correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        await this.viewConsultation(this.consultations.id)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al borrar el archivo`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
    },
    async viewConsultation(id) {
      await this.getAllDoctors();
      this.updateInformation = false
      this.createConsultations = false;
      this.consultations = {};
      this.extra_select = {id: "", value: "Seleccione un medico"};
      this.consultations = await this.findPatientOneInterConsultationsDesc(id);
      await this.getDiagnosisUpdate();
      if (this.consultations.medical_inter_consultations_id) {
        const verifyMedical = await this.medicalsAll.find((a) => a.id === this.consultations.medical_inter_consultations_id);
        this.extra_select.id = verifyMedical.id;
        this.extra_select.value = verifyMedical.name;

      }

      this.updateInformation = true
    },

    async createConsult() {
      const responseVerify = await this.verifyData();
      if (!responseVerify) return;
      this.show = true

      this.consul.appointee_id = this.data_patient.appointee_id
      this.consul.patient_id = this.data_patient.id
      const response = await this.createInterConsultation(this.consul)
      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })

        this.consul = {
          address: null,
          name: null,
          identification_medical: null,
          phone: null,
          specialty: null,
          appointee_id: null,
          patient_id: null,
        }
        this.show = false
        this.createConsultations = false;
        await this.getConsultations()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
      }
    },
    async newConsultations() {
      this.extra_select = {id: "", value: "Seleccione un medico"};
      this.consultations = {};
      this.createConsultations = true
      this.updateInformation = false
      await this.getAllDoctors();


    },
    async exportInterPrint() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      const pageHeight = doc.internal.pageSize.height;
      img.src = logo;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Interconsulta`, 225, 50);
      doc.setFontSize(13);
      doc.text(
          `Fecha: ${formatHour(this.consul.date_consultation_hours)}`,
          400,
          50
      );
      doc.setFontSize(18);
      doc.text(`Información de mi doctor:`, 40, 110);
      doc.setFontSize(13);
      doc.text(`${this.consul.name_doctor ? this.consul.name_doctor : ""}`, 40, 130);
      doc.text(
          `RFC: ${
              this.consul.vat ? this.consul.vat : ""
          }`,
          340,
          130
      );
      doc.text(`Especialidad: ${this.consul.study_field ? this.consul.study_field : ''}`, 40, 150);
      doc.text(`Cedula: ${this.consul.permit_no ? this.consul.permit_no : ''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} / ${this.consul.study_school ? this.consul.study_school : ''}`, 40, 175);
      doc.text(`Nombre Paciente:`, 40, 200);
      doc.text(`${this.data_patient.name}`, 40, 215);

      if (this.consul.years) {
        doc.text(`Edad:`, 340, 200);
        doc.text(`${this.consul.years ? this.consul.years : ""} Años`, 340, 215);
      } else {
        doc.text(`Edad:`, 340, 200);
      }

      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 430, 200);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 430, 215);
      } else {
        doc.text(`Fecha Nacimiento:`, 430, 200);
      }
      doc.setFontSize(18);
      doc.text(`Datos de medico relacionado:`, 40, 250);
      doc.setFontSize(13);
      doc.text(`Nombre: ${this.consul.name ? this.consul.name : ""}`, 40, 280);
      doc.text(`Especialidad del Médico: ${this.consul.specialty ? this.consul.specialty : ""}`, 40, 310);
      doc.text(`Teléfono: ${this.consul.phone ? this.consul.phone : ""}`, 40, 340);
      doc.text(`Cedula: ${this.consul.identification_medical ? this.consul.identification_medical : ""}`, 40, 370);
      const dataAddress = `Dirección: ${this.consul.address ? this.consul.address : ""}`
      var addressData = doc.splitTextToSize(dataAddress, 500);
      doc.text(addressData, 40, 400);
      let currentHeight = 450;
      doc.text(`Diagnostico de la ultima consulta:`, 40, currentHeight);
      if (this.consul.last_consult) {
        var strArr = doc.splitTextToSize(this.consul.last_consult, 500);
        currentHeight += 20;
        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
            doc.text(`Diagnostico de la ultima consulta:`, 40, 40);
            currentHeight = 70;
          }
          doc.text(line, 40, currentHeight);
          currentHeight += 15;
        });
      }
      if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
        currentHeight = 40;
      }
      doc.text(`Motivo:`, 40, currentHeight);

      if (this.consul.reason) {
        var splitText = doc.splitTextToSize(this.consul.reason, 540);
        currentHeight += 20;
        splitText.forEach((line, index) => {
          if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
            doc.text(`Motivo:`, 40, 40);
            currentHeight = 70;
          }
          doc.text(line, 40, currentHeight);
          currentHeight += 15;
        });
      }

      if (this.checkAndAddNewPagePt((currentHeight + 120), pageHeight, doc)) {
        currentHeight = 40;
      }


      const send = {
        date_consultation: this.consul.date_consultation_hours ? this.consul.date_consultation_hours : "---",
        name_doctor: this.consul.name_doctor ? this.consul.name_doctor : "---",
        permit_no: this.consul.permit_no ? this.consul.permit_no : "---",
        vat: this.consul.vat ? this.consul.vat : "---",
      }

      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, currentHeight, 120, 120);

      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }
        this.show = true

        const responseDataSignature = await this.getFindFullName(this.getUser.id);


        this.show = false
        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.consul.name_doctor || ''} ${this.consul.study_field? this.consul.study_field : ''} ` ;
          const footerText = `Cedula: ${this.consul.permit_no ? this.consul.permit_no :''} / Cedula de especialidad: ${this.consul.visa_no ? this.consul.visa_no : ''} ${this.consul.study_school ? this.consul.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 40, footerPosition - 25);
          doc.text(footerText, 40, footerPosition - 40);
          doc.text(footerName, 40, footerPosition-55);

          if (responseDataSignature && responseDataSignature.signatureFile){
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
          }


        }
      };

      await addFooters(doc);
      doc.save(
          `${this.data_patient.name}-${this.consul.date_consultation_hours}.pdf`
      );
    },
    async exportInterPrintUpdate() {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      const pageHeight = doc.internal.pageSize.height;
      img.src = logo;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Interconsulta`, 225, 50);
      doc.setFontSize(13);
      console.log(this.consultations.date_consultation_hours)
      doc.text(
          `Fecha: ${ this.consultations.date_consultation_hours ? formatHour(this.consultations.date_consultation_hours): dateTodayFormat()}`,
          400,
          50
      );
      doc.setFontSize(18);
      doc.text(`Información de mi doctor:`, 40, 110);
      doc.setFontSize(13);
      doc.text(`${this.consultations.name_doctor ? this.consultations.name_doctor : ""}`, 40, 130);
      doc.text(
          `RFC: ${
              this.consultations.vat ? this.consultations.vat : ""
          }`,
          340,
          130
      );
      doc.text(`Especialidad: ${this.consultations.study_field ? this.consultations.study_field : ''}`, 40, 150);
      doc.text(`Cedula: ${this.consultations.permit_no ? this.consultations.permit_no : ''} / Cedula de especialidad: ${this.consultations.visa_no ? this.consultations.visa_no : ''} / ${this.consultations.study_school ? this.consultations.study_school : ''}`, 40, 175);
      doc.text(`Nombre Paciente:`, 40, 200);
      doc.text(`${this.data_patient.name}`, 40, 215);

      if (this.consultations.years) {
        doc.text(`Edad:`, 340, 200);
        doc.text(`${this.consultations.years ? this.consultations.years : ""} Años`, 340, 215);
      } else {
        doc.text(`Edad:`, 340, 200);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 430, 200);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 430, 215);
      } else {
        doc.text(`Fecha Nacimiento:`, 430, 200);
      }
      doc.setFontSize(18);
      doc.text(`Datos de medico relacionado:`, 40, 250);
      doc.setFontSize(13);
      doc.text(`Nombre: ${this.consultations.name ? this.consultations.name : ""}`, 40, 280);
      doc.text(`Especialidad del Médico: ${this.consultations.specialty ? this.consultations.specialty : ""}`, 40, 310);
      doc.text(`Teléfono: ${this.consultations.phone ? this.consultations.phone : ""}`, 40, 340);
      doc.text(`Cedula: ${this.consultations.identification_medical ? this.consultations.identification_medical : ""}`, 40, 370);
      const dataAddress = `Dirección: ${this.consultations.address ? this.consultations.address : ""}`
      var addressData = doc.splitTextToSize(dataAddress, 500);
      doc.text(addressData, 40, 400);
      let currentHeight = 460;
      doc.text(`Diagnostico de la ultima consulta:`, 40, currentHeight);
      if (this.consultations.last_consult) {
        var strArr = doc.splitTextToSize(this.consultations.last_consult, 500);
        currentHeight += 20;
        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
            doc.text(`Diagnostico de la ultima consulta:`, 40, 40);
            currentHeight = 70;
          }
          doc.text(line, 40, currentHeight);
          currentHeight += 15;
        });
      }
      if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
        currentHeight = 40;
      }
      doc.text(`Motivo:`, 40, currentHeight);

      if (this.consultations.reason) {
        var splitText = doc.splitTextToSize(this.consultations.reason, 540);
        currentHeight += 20;
        splitText.forEach((line, index) => {
          if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
            doc.text(`Motivo:`, 40, 40);
            currentHeight = 70;
          }
          doc.text(line, 40, currentHeight);
          currentHeight += 15;
        });
      }

      if (this.checkAndAddNewPagePt((currentHeight + 120), pageHeight, doc)) {
        currentHeight = 40;
      }


      const send = {
        date_consultation: this.consultations.date_consultation_hours ? this.consultations.date_consultation_hours : "---",
        name_doctor: this.consultations.name_doctor ? this.consultations.name_doctor : "---",
        permit_no: this.consultations.permit_no ? this.consultations.permit_no : "---",
        vat: this.consultations.vat ? this.consultations.vat : "---",
      };

      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, currentHeight, 120, 120);
      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }

        this.show = true

        const responseDataSignature = await this.getFindFullName(this.consultations.hr_employee_id);


        this.show = false


        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.consultations.name_doctor || ''} ${this.consultations.study_field? this.consultations.study_field : ''} ` ;
          const footerText = `Cedula: ${this.consultations.permit_no ? this.consultations.permit_no :''} / Cedula de especialidad: ${this.consultations.visa_no ? this.consultations.visa_no : ''} ${this.consultations.study_school ? this.consultations.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 40, footerPosition - 25);
          doc.text(footerText, 40, footerPosition - 40);
          doc.text(footerName, 40, footerPosition-55);
          if (responseDataSignature && responseDataSignature.signatureFile){
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "png", 400, footerPosition - 150, 80, 80);
          }
        }
      };

      await addFooters(doc);
      doc.save(
          `${this.data_patient.name}-${this.consultations.date_consultation_hours}.pdf`
      );
    },
    resetModal() {
    },
    async showModalMedicals() {
      await this.$bvModal.show('modal-prevent-closing-extra-medical')
    },
    async addInfoCreateMedical(id, type){
      if (type === 'create'){
        this.consul.medical_inter_consultations_id = id;
        const findMedical = this.medicalsAll.find((a) => a.id === id);
        if (!findMedical) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este medico',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.extra_select.id = id;
        this.extra_select.value = findMedical.name;

        this.consul.name = findMedical.name;
        this.consul.identification_medical = findMedical.identification_medical;
        this.consul.address = findMedical.address;
        this.consul.specialty = findMedical.specialty;
        this.consul.phone = findMedical.phone;
      }else{
        this.consultations.medical_inter_consultations_id = id;
        const findMedical = this.medicalsAll.find((a) => a.id === id);
        if (!findMedical) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se encontro informacion sobre este medico',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return
        }
        this.extra_select.id = id;
        this.extra_select.value = findMedical.name;
        this.consultations.name = findMedical.name;
        this.consultations.identification_medical = findMedical.identification_medical;
        this.consultations.address = findMedical.address;
        this.consultations.specialty = findMedical.specialty;
        this.consultations.phone = findMedical.phone;
      }


    },
    async createMedical() {
      if (!this.medicalExtra.name){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Nombre requerido`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.medicalExtra.specialty){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Especialidad`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.medicalExtra.phone){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Teléfono requerido`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }

      this.show = true
      const response = await this.createExtraMedical(this.medicalExtra);
      if (response.status) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.medicalExtra.name = null;
        this.medicalExtra.phone = null;
        this.medicalExtra.specialty = null;
        this.medicalExtra.identification_medical = null;
        this.medicalExtra.address = null;
        await this.getAllDoctors();
        if (this.createConsultations){
          await this.addInfoCreateMedical(response.msg , 'create')
        }else{
          await this.addInfoCreateMedical(response.msg , 'edit')
        }

        await this.$bvModal.hide('modal-prevent-closing-extra-medical')
        this.show = false
      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al crear medico`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }

    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },

}
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

</style>
